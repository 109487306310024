import '../../css/footer.css'
import Gpc from '../../assets/gpc.png'
import tucuman from '../../assets/tucuman.png'
import clera from '../../assets/clera.png'
import brcgs from '../../assets/brcgslogo.png'
import haccp from '../../assets/haccp.png'
import pin from '../../assets/footer/pin.svg'
import phone from '../../assets/footer/Phone.png'
import instagram from '../../assets/footer/instagram.svg'
import facebook from '../../assets/footer/facebook.png'
import cas from '../../assets/cas-logo.png'
import mindStudio from '../../assets/mindStudio.png'
import Radix from '../../assets/Radix.svg'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

export const Footer = () => {
    const { t, i18n } = useTranslation(["translation"])
    return (
        <footer className="mainFooter">
            <header className='mainFooter__header'>
                <img src={cas} alt="Cas Exportaciones" className="mainFooter__logo" />
                <h4 className='mainFooter__slogan'>"More than beans"</h4>
            </header>
            <section className='mainFooter__column mainFooter__contact'>
                <h3>{t("footer.social.title")}</h3>
                <a href="#"><img src={phone} alt="Telefono" />+54 381 421-5581 · int. 191 - 192</a>
                <a href="https://www.instagram.com/cas_exportaciones/" target='blank'><img src={instagram} alt="Instagram" />cas_exportaciones</a>
                <a href="https://www.facebook.com/profile.php?id=100063670653857&locale=es_LA" target='blank'><img src={facebook} alt="Facebook" />cas_exportaciones</a>
                <a href="https://maps.app.goo.gl/S6Bwtogepyi6kfCJA" target='blank'><img src={pin} alt="Ubicación" />{t("footer.social.addressOffice")}</a>
                <a href="https://maps.app.goo.gl/2XBwvst6br4nLZgs7" target='blank'><img src={pin} alt="Ubicación" />{t("footer.social.addressPlant")}</a>
            </section>
            <section className='mainFooter__column mainFooter__institutions'>
                <h3>{t("footer.institutions.associated")}</h3>
                <div className='institutions__container'><img src={Gpc} alt="" /><img src={tucuman} alt="" /><img src={clera} alt="" /></div>
                <h3>{t("footer.institutions.certificates")}</h3>
                <div className='certificates__container'><img src={brcgs} alt="Brcgs logo" /></div>

            </section>
            <section className='mainFooter__column mainFooter__links'>
                <h3>{t("footer.links.title")}</h3>
                <Link to={"/contacto"}>{t("footer.links.contact")}</Link><Link to={"/nosotros"}>{t("footer.links.aboutUs")}</Link><Link to={"/politicas"}>{t("footer.links.policies")}</Link>
            </section>
            <section className="mainFooterauthor">
                <h4>{t("footer.developedBy")}</h4>
                <a href="https://www.radixweb.com.ar/" target='blank'>
                    <img className='radix' src={Radix} alt="Radix web development" />
                </a>
                <h4>&</h4>
                <a href="https://wa.me/543815941618">
                    <img className='mindStudio' src={mindStudio} alt="mindstudio" />
                </a>
            </section>
        </footer>
    )
}
