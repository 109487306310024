import React from "react";
import "./css/app.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/common/scrollToTop";
import { Navigate } from "react-router-dom";
import { Header } from "./components/common/Header";
import { Footer } from "./components/common/Footer";
import { Inicio } from "./components/views/Inicio";
import { Planta } from "./components/views/Planta";
import { Politicas } from "./components/views/PoliticasCalidad";
import { Contacto } from "./components/views/Contacto";
import { Calendario } from "./components/views/Calendario";
import { Error } from "./components/views/Error";

function App() {
    return (
        <Router>
            <ScrollToTop />
            <Header />
            <Routes>
                <Route path="/" element={<Inicio />} />
                <Route path="/nosotros" element={<Planta />} />
                <Route path="/politicas" element={<Politicas />} />
                <Route path="/contacto" element={<Contacto />} />
                <Route path="/calendario" element={<Calendario />} />
                <Route path="/404" element={<Error />} />
                <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
