import '../../css/politicas.css'
import campoSoja from '../../assets/inicio/banner/campoSoja.jpg'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Politicas = () => {
    const { t, i18n } = useTranslation(["translation"])
    return (
        <main className="paginaPoliticas">
            <section className="paginaPoliticas__infoSection">
                <h1 className="paginaPoliticas__title">{t("policies.title")}</h1>
                <p className="paginaPoliticas__text">{t("policies.text")}</p>
                <ul className="paginaPoliticas__text paginaPoliticas__ul">
                    <li className="paginaPoliticas__li">{t("policies.li1")}</li>
                    <li className="paginaPoliticas__li">{t("policies.li2")}</li>
                    <li className="paginaPoliticas__li">{t("policies.li3")}</li>
                    <li className="paginaPoliticas__li">{t("policies.li4")}</li>
                    <li className="paginaPoliticas__li">{t("policies.li5")}</li>
                    <li className="paginaPoliticas__li">{t("policies.li6")}</li>
                    <li className="paginaPoliticas__li">{t("policies.li7")}</li>
                </ul>
                <div className='paginaPoliticas__dflex'>

                    <Link to={"/contacto"} className="paginaPoliticas__btn">{t("policies.contact")}</Link>
                    <p className="paginaPoliticas__firma">Carlos Salas<br />{t("policies.sign")}</p>
                    <p className="paginaPoliticas__firma">Agustín Salas<br />{t("policies.sign")}</p>
                </div>
            </section>
            <img src={campoSoja} alt="campo de soja" className='paginaPoliticas__img' />
        </main>
    )
}