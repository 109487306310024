import "../../css/app.css";
import { Banner } from "../views/Banner";
import { Productos } from "../views/Productos";
import { Redireccion } from "../views/Redireccion";
import { MapaPaises } from "../views/MapaPaises";
import { Estadisticas } from "../views/Estadisticas";
import { Carrousel } from "../views/Carrousel";
import { CardsMision } from "../views/CardsMision";
import { Instituciones } from "../views/Instituciones";
import info from "./SliderItems.jsx";
import { useTranslation } from "react-i18next";

export const Inicio = () => {
    const { t, i18n } = useTranslation(["translation"]);
    const ferias = {
        title: t("homePage.events"),
        itemsArray: info[0],
    };

    return (
        <main className="paginaInicio">
            <Banner />
            <Productos />
            <Redireccion />
            <MapaPaises />
            <Estadisticas />
            <Carrousel display={ferias} shadow={true} />
            <CardsMision />
            <Instituciones />
        </main>
    );
};
