import "../../css/politicas.css";
import "../../css/error.css";
import campoSoja from "../../assets/inicio/banner/campoSoja.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Error = () => {
    const { t, i18n } = useTranslation(["translation"]);
    return (
        <main className="paginaPoliticas paginaError">
            <section className="paginaPoliticas__infoSection">
                <h1 className="paginaPoliticas__title" id="errorTitle">
                    {t("error.title")}
                </h1>
                <p className="paginaPoliticas__text" id="errorParagraph">
                    {t("error.paragraph")}
                </p>

                <Link to={"/"} className="paginaPoliticas__btn" id="errorBtn">
                    {t("error.returnBtn")}
                </Link>
            </section>
            <img
                src={campoSoja}
                alt="campo de soja"
                className="paginaPoliticas__img"
            />
        </main>
    );
};
