import React from "react";
import { Carrousel } from "../views/Carrousel";
import "../../css/planta.css";
import info from "./SliderItems.jsx";
import horizontal from "../../assets/Industria 2/Horizontal.mp4";
import vertical1 from "../../assets/Industria 2/01.mp4";
import procesoDesktop from "../../assets/Procesoproceso.svg";
import procesoMobile from "../../assets/ProcesoprocesoVertical.svg";
import { useTranslation } from "react-i18next";

export const Planta = () => {
    const { t, i18n } = useTranslation(["translation"]);
    const planta = {
        title: t("aboutUs.sliderTitle"),
        itemsArray: info[1],
    };

    const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 540;
    React.useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);

    return (
        <main className="nosotros">
            <section className="nosotros__videos">
                <div className="nosotros__bg"></div>
                <div className="section__container">
                    <h1 className="nosotrosTitle">{t("aboutUs.title")}</h1>
                    <div className="nosotros__videoContainer">
                        {width > breakpoint ? (
                            <iframe
                                className="nosotros__Video desktop"
                                width="500"
                                height="550"
                                src="https://www.youtube.com/embed/y2-TyPnsekk?si=lfg2fO6yGbMhCHvR"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                            ></iframe>
                        ) : (
                            <video className="nosotros__Video mobile" controls>
                                <source
                                    src={vertical1}
                                    type="video/mp4"
                                ></source>
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </div>
                    <p className="nosotros__videoText">
                        {t("aboutUs.textVideo")}
                    </p>
                </div>
            </section>
            <Carrousel display={planta} />
            <section className="nosotros__proceso">
                <h2 className="textoNosotrosProceso">{t("aboutUs.process")}</h2>
                <img
                    src={procesoDesktop}
                    alt=""
                    className="nosotros__procesoImg desktop"
                />
                <img
                    src={procesoMobile}
                    alt=""
                    className="nosotros__procesoImg mobile"
                />
            </section>
        </main>
    );
};
