import "../../css/redireccion.css";
import cosecha from "../../assets/cosecha.png";
import planta from "../../assets/planta.png";
import politicas from "../../assets/politicas.png";
import contactar from "../../assets/contactar.png";
import calendario from "../../assets/Calendario de Cosecha Cas.pdf";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Redireccion = () => {
    const { t, i18n } = useTranslation(["translation"]);
    return (
        <section className="generalRedireccion">
            <h2 className="generalRedireccionTitle">
                {t("homePage.redirections.sectionTitle")}
            </h2>
            <article className="ordenCardsRedireccion">
                <div className="cardsRedireccion">
                    <img
                        alt="Semillas"
                        src={cosecha}
                        className="imagenCardsRedireccion"
                    />
                    <Link
                        to={"/calendario"}
                        className="textoCardsRedireccion"
                        href={calendario}
                        download={"Calendario de cosecha Cas Exportaciones"}
                    >
                        {t("homePage.redirections.calendar")}
                    </Link>
                </div>
                <div className="cardsRedireccion">
                    <img
                        alt="Nuestra planta"
                        src={planta}
                        className="imagenCardsRedireccion"
                    />
                    <Link to={"/nosotros"} className="textoCardsRedireccion">
                        {t("homePage.redirections.ourPlant")}
                    </Link>
                </div>
                <div className="cardsRedireccion">
                    <img
                        alt="Firma de contrato"
                        src={politicas}
                        className="imagenCardsRedireccion"
                    />
                    <Link to={"/politicas"} className="textoCardsRedireccion">
                        {t("homePage.redirections.policies")}
                    </Link>
                </div>
                <div className="cardsRedireccion">
                    <img
                        alt="barco mercante"
                        src={contactar}
                        className="imagenCardsRedireccion"
                    />
                    <Link to={"/contacto"} className="textoCardsRedireccion">
                        {t("homePage.redirections.contact")}
                    </Link>
                </div>
            </article>
        </section>
    );
};
