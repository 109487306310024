import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import apiCas from "../../api/ApiCas";
import "../../css/contacto.css";

export const Contacto = () => {
    const { t, i18n } = useTranslation(["translation"]);
    const [nombre, setNombre] = useState("");
    const [email, setEmail] = useState("");
    const [motivo, setMotivo] = useState("");
    const [errores, setErrores] = useState({
        nombre: "",
        email: "",
        motivo: "",
    });
    const [loading, setLoading] = useState(false);

    const validarFormulario = () => {
        let erroresFormulario = {};

        if (!nombre.trim()) {
            erroresFormulario.nombre = t("contact.nameValidation");
        } else if (nombre.length > 50) {
            erroresFormulario.nombre = t("contact.nameValidation2");
        }

        if (!email.trim()) {
            erroresFormulario.email = t("contact.emailValidation");
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            erroresFormulario.email = t("contact.emailValidation2");
        } else if (email.length > 100) {
            erroresFormulario.email = t("contact.emailValidation3");
        }

        if (!motivo.trim()) {
            erroresFormulario.motivo = t("contact.reasonValidation");
        } else if (motivo.length > 500) {
            erroresFormulario.motivo = t("contact.reasonValidation2");
        }

        setErrores(erroresFormulario);

        return Object.keys(erroresFormulario).length === 0;
    };

    const handleSubmit = async e => {
        e.preventDefault();

        if (validarFormulario()) {
            try {
                setLoading(true);
                const response = await apiCas.post("/api/contacto/", {
                    nombre,
                    email,
                    motivo,
                });

                const data = response.data;

                if (data.success) {
                    setNombre("");
                    setEmail("");
                    setMotivo("");
                    Swal.fire(
                        "Formulario enviado correctamente",
                        "Nos pondremos en contacto contigo pronto",
                        "success"
                    );
                } else {
                    Swal.fire(
                        "Error al enviar el formulario",
                        "Inténtelo nuevamente",
                        "error"
                    );
                }
            } catch (error) {
                console.error("Error en la solicitud:", error);
                let errorMessage =
                    "Ocurrió un error inesperado. Intentelo nuevamente o escriba a contacto@radixweb.com.ar";

                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    errorMessage = error.response.data.message;
                }

                Swal.fire(
                    "Error al enviar el formulario",
                    errorMessage,
                    "error"
                );
            } finally {
                setLoading(false);
            }
        } else {
            console.log(
                "Formulario inválido, corrige los errores antes de enviar"
            );
        }
    };

    return (
        <>
            <section className="ordenGeneralContacto">
                <div className="barraVerdeContacto">
                    <h3 className="tituloContacto">{t("contact.title")}</h3>
                </div>
                <article className="seccionCardsContacto">
                    <div className="textoContacto card">
                        <a
                            target="blank"
                            href="https://wa.me/+543815390678"
                            className="ordenParrafoContacto"
                        >
                            <i className="fa-solid fa-phone iconoContacto"></i>{" "}
                            +54 381 5390678
                        </a>
                        <br />
                        <a
                            target="blank"
                            href="https://www.instagram.com/cas_exportaciones/"
                            className="ordenParrafoContacto"
                        >
                            <i className="fa-brands fa-instagram iconoContacto"></i>{" "}
                            cas_exportaciones
                        </a>
                        <br />
                        <a
                            target="blank"
                            href="https://www.facebook.com/profile.php?id=100063670653857&locale=es_LA"
                            className="ordenParrafoContacto"
                        >
                            <i className="fa-brands fa-square-facebook iconoContacto"></i>{" "}
                            cas_exportaciones
                        </a>
                        <br />
                        <a
                            target="blank"
                            href="https://maps.app.goo.gl/2XBwvst6br4nLZgs7"
                            className="ordenParrafoContacto"
                        >
                            <i className="fa-solid fa-industry iconoContacto"></i>{" "}
                            {t("contact.addressPlant")}
                        </a>
                        <br />
                        <a
                            target="blank"
                            href="https://maps.app.goo.gl/S6Bwtogepyi6kfCJA"
                            className="ordenParrafoContacto"
                        >
                            <i className="fa-solid fa-location-dot iconoContacto"></i>{" "}
                            {t("contact.addressOffice")}
                        </a>
                    </div>
                    <div className="seccionFormContacto card">
                        <b className="tituloFormContacto">
                            {t("contact.formTitle")}
                        </b>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <p className="textoFormContacto">
                                    {t("contact.name")}
                                </p>
                                <input
                                    type="text"
                                    placeholder={t("contact.namePH")}
                                    value={nombre}
                                    onChange={e => setNombre(e.target.value)}
                                    className="inputContacto"
                                />
                                <p className="error">{errores.nombre}</p>
                            </div>
                            <div>
                                <p className="textoFormContacto">
                                    {t("contact.email")}
                                </p>
                                <input
                                    type="email"
                                    placeholder={t("contact.emailPH")}
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    className="inputContacto"
                                />
                                <p className="error">{errores.email}</p>
                            </div>
                            <div>
                                <p className="textoFormContacto">
                                    {t("contact.reason")}
                                </p>
                                <textarea
                                    placeholder={t("contact.reasonPH")}
                                    value={motivo}
                                    onChange={e => setMotivo(e.target.value)}
                                    maxLength={350}
                                    rows={3}
                                    className="inputContacto"
                                />
                                <p className="error">{errores.motivo}</p>
                            </div>
                            <div className="ordenBotonContacto">
                                <button
                                    type="submit"
                                    className="botonFormContacto btn"
                                >
                                    {t("contact.send")}
                                </button>
                            </div>
                        </form>
                    </div>
                </article>
            </section>
        </>
    );
};
