import "../../css/calendario.css";
import calendario from "../../assets/calendarioCas.png";
import { useTranslation } from "react-i18next";

export const Calendario = () => {
    const { t, i18n } = useTranslation(["translation"]);
    return (
        <>
            <main className="ordenCalendario">
                <section className="seccionTitulo">
                    <h2 className="tituloCalendario">
                        {" "}
                        {t("homePage.redirections.title")}{" "}
                    </h2>
                </section>
                <img
                    src={calendario}
                    alt="calendario de cas"
                    className="calendarioCas"
                />
            </main>
        </>
    );
};
