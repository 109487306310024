import '../../css/cardsmision.css'
import { useState } from 'react'
import { useTranslation } from "react-i18next";


export const CardsMision = () => {
    const { t, i18n } = useTranslation(["translation"])
    function CardMisionVision(object) {

        const [cardState, setCardState] = useState("")
        function changeCardState() {
            setCardState(cardState == "" ? "active" : "")
        }
        return (
            <article className='cardMision'>
                <div className="cardMisionBgFilter"></div>
                <h3 className='tituloCardMision'> {object.object.title} </h3>
                <p className={'textoCardMision ' + cardState}> {cardState == "active" ? object.object.textoLargo : object.object.textoCorto} </p>
                <button className='botonCardMision' onClick={changeCardState}> {cardState == "" ? t("homePage.cards.seeMoreBtn") : t("homePage.cards.seeLessBtn")}</button>
            </article>
        )

    }


    const textosTrayectoria = {
        title: t("homePage.cards.trajectoryTitle"),
        textoCorto: t("homePage.cards.trajectorySmText"),
        textoLargo: t("homePage.cards.trajectoryLgText")
    }
    const textosMision = {
        title: t("homePage.cards.missionTitle"),
        textoCorto: t("homePage.cards.missionSmText"),
        textoLargo: t("homePage.cards.missionLgText")
    }
    const textosVision = {
        title: t("homePage.cards.visionTitle"),
        textoCorto: t("homePage.cards.visionSmText"),
        textoLargo: t("homePage.cards.visionLgText")
    }


    return (
        <section className='ordenGeneralCardMision'>
            <CardMisionVision object={textosTrayectoria} />
            <CardMisionVision object={textosMision} />
            <CardMisionVision object={textosVision} />

        </section>
    )
}




