/* nombre + año + numero de foto "clera" + "2023" + "foto 1" */

import sial20241 from "../../assets/EVENTOS/2024 Sial Paris/sial2024-1.jpg";
import sial20242 from "../../assets/EVENTOS/2024 Sial Paris/sial2024-2.jpg";
import sial20243 from "../../assets/EVENTOS/2024 Sial Paris/sial2024-3.jpg";
import sial20244 from "../../assets/EVENTOS/2024 Sial Paris/sial2024-4.jpg";
import sial20245 from "../../assets/EVENTOS/2024 Sial Paris/sial2024-5.jpg";


import dubai20241 from "../../assets/EVENTOS/2024 Gulfood Dubai/Gulfood20241.jpeg"
import dubai20242 from "../../assets/EVENTOS/2024 Gulfood Dubai/Gulfood20242.jpeg"

import india2024 from "../../assets/EVENTOS/2024 GPC India/GPC241.jpeg"

import clera20231 from "../../assets/EVENTOS/2023 Jornada Clera/clera.jpeg";

import gulfood20231 from "../../assets/EVENTOS/2023 Gulfood Dubai/gulfood.jpeg";
import gulfood20232 from "../../assets/EVENTOS/2023 Gulfood Dubai/gulfood2.jpeg";
import gulfood20233 from "../../assets/EVENTOS/2023 Gulfood Dubai/gulfood3.jpeg";

import gpc20231 from "../../assets/EVENTOS/2023 Gpc Australia/gpc1.jpeg";
import gpc20232 from "../../assets/EVENTOS/2023 Gpc Australia/gpc2.jpeg";
import gpc20233 from "../../assets/EVENTOS/2023 Gpc Australia/gpc3.jpeg";

import anuga20231 from "../../assets/EVENTOS/2023 Anuga/anuga1.jpeg";
import anuga20232 from "../../assets/EVENTOS/2023 Anuga/anuga2.jpeg";
import anuga20233 from "../../assets/EVENTOS/2023 Anuga/anuga3.jpeg";
import anuga20234 from "../../assets/EVENTOS/2023 Anuga/anuga4.jpeg";
import anuga20235 from "../../assets/EVENTOS/2023 Anuga/anuga5.jpeg";

import sial20221 from "../../assets/EVENTOS/2022 Sial Paris/sial1.jpg";
import sial20222 from "../../assets/EVENTOS/2022 Sial Paris/sial2.jpg";

import clera20221 from "../../assets/EVENTOS/2022 Jornada Clera/clera.jpeg";

import gulfood20221 from "../../assets/EVENTOS/2022 Gulfood Dubai/gulfood1.jpeg";
import gulfood20222 from "../../assets/EVENTOS/2022 Gulfood Dubai/gulfood2.jpeg";
import gulfood20223 from "../../assets/EVENTOS/2022 Gulfood Dubai/gulfood3.jpeg";

import gulfood20201 from "../../assets/EVENTOS/2020 Gulfood/gulfood1.jpeg";
import gulfood20202 from "../../assets/EVENTOS/2020 Gulfood/gulfood2.jpeg";
import gulfood20203 from "../../assets/EVENTOS/2020 Gulfood/gulfood3.jpeg";
import gulfood20204 from "../../assets/EVENTOS/2020 Gulfood/gulfood4.jpeg";
import gulfood20205 from "../../assets/EVENTOS/2020 Gulfood/gulfood5.jpeg";

import gulfood20191 from "../../assets/EVENTOS/2019 Gulfood Dubai/gulfood1.jpg";
import gulfood20192 from "../../assets/EVENTOS/2019 Gulfood Dubai/gulfood2.jpg";
import gulfood20193 from "../../assets/EVENTOS/2019 Gulfood Dubai/gulfood3.jpg";

import anuga20191 from "../../assets/EVENTOS/2019 Anuga Colonia/anuga1.jpg";
import anuga20192 from "../../assets/EVENTOS/2019 Anuga Colonia/anuga2.jpg";

import sial20181 from "../../assets/EVENTOS/2018 Sial Paris/sial1.jpg";
import sial20182 from "../../assets/EVENTOS/2018 Sial Paris/sial2.jpg";

import gulfood20181 from "../../assets/EVENTOS/2018 Gulfood/gulfood1.jpeg";
import gulfood20182 from "../../assets/EVENTOS/2018 Gulfood/gulfood2.jpeg";
import gulfood20183 from "../../assets/EVENTOS/2018 Gulfood/gulfood3.jpeg";
import gulfood20184 from "../../assets/EVENTOS/2018 Gulfood/gulfood4.jpeg";

import forum20171 from "../../assets/EVENTOS/2017 Forum Brasileiro do Feijao/forum1.jpg";
import forum20172 from "../../assets/EVENTOS/2017 Forum Brasileiro do Feijao/forum2.jpeg";

import anuga20171 from "../../assets/EVENTOS/2017 Anuga Colonia/anuga1.jpg";
import anuga20172 from "../../assets/EVENTOS/2017 Anuga Colonia/anuga2.jpg";

import forum20161 from "../../assets/EVENTOS/2016 Forum Brasileiro de Feijao/forum1.jpg";
import forum20162 from "../../assets/EVENTOS/2016 Forum Brasileiro de Feijao/forum2.jpeg";

import anuga20161 from "../../assets/EVENTOS/2016 Anuga/anuga1.jpeg";
import anuga20162 from "../../assets/EVENTOS/2016 Anuga/anuga2.jpeg";
import anuga20163 from "../../assets/EVENTOS/2016 Anuga/anuga3.jpeg";
import anuga20164 from "../../assets/EVENTOS/2016 Anuga/anuga4.jpeg";

import gulfood20161 from "../../assets/EVENTOS/2016 Gulfood/gulfood1.jpeg";
import gulfood20162 from "../../assets/EVENTOS/2016 Gulfood/gulfood2.jpeg";
import gulfood20163 from "../../assets/EVENTOS/2016 Gulfood/gulfood3.jpeg";

import apa20151 from "../../assets/EVENTOS/2015 Apa San Pablo/apa1.jpeg";
import apa20152 from "../../assets/EVENTOS/2015 Apa San Pablo/apa2.jpeg";
import apa20153 from "../../assets/EVENTOS/2015 Apa San Pablo/apa3.jpeg";


  //? Inicio imagenes Planta

import planta1 from "../../assets/planta/planta1.jpg";
import planta2 from "../../assets/planta/planta2.jpeg";
import planta3 from "../../assets/planta/planta3.jpg";
import planta4 from "../../assets/planta/planta4.jpeg";
import planta5 from "../../assets/planta/planta5.jpeg";
import planta6 from "../../assets/planta/planta6.jpg";
import planta7 from "../../assets/planta/planta7.jpg";
import planta8 from "../../assets/planta/planta8.jpg";
import planta9 from "../../assets/planta/planta9.jpg";
import planta10 from "../../assets/planta/planta10.jpg";
import planta11 from "../../assets/planta/planta11.jpeg";
import planta12 from "../../assets/planta/planta12.jpeg";
import planta13 from "../../assets/planta/planta13.jpeg";
import planta14 from "../../assets/planta/planta14.jpeg";
import planta15 from "../../assets/planta/planta15.jpeg";
import planta16 from "../../assets/planta/planta16.jpg";
import planta17 from "../../assets/planta/planta17.jpeg";
import planta18 from "../../assets/planta/planta18.jpeg";

let infoFerias = [];
let infoPlanta = [];

const info = [
    (infoFerias = [
        {
            title: "2024 Sial Paris",
            imgUrl: [sial20241, sial20242, sial20243, sial20244, sial20245],
        },

        {
            title: "2024 Gulfood Dubai",
            imgUrl:[dubai20242,dubai20241],
        },
        {
            title: "2024 GPC India",
            imgUrl:[india2024],
        },
        {
            title: "2023 Jornada Clera",
            imgUrl: [clera20231],
        },
        {
            title: "2023 GPC Australia",
            imgUrl: [gpc20231, gpc20232, gpc20233],
        },
        {
            title: "2023 Gulfood Dubai",
            imgUrl: [gulfood20231, gulfood20232, gulfood20233],
        },
        {
            title: "2023 Anuga",
            imgUrl: [
                anuga20231,
                anuga20232,
                anuga20233,
                anuga20234,
                anuga20235,
            ],
        },
        {
            title: "2022 Sial Paris",
            imgUrl: [sial20221, sial20222],
        },
        {
            title: "2022 Jornada Clera",
            imgUrl: [clera20221],
        },
        {
            title: "2022 Gulfood Dubai",
            imgUrl: [gulfood20221, gulfood20222, gulfood20223],
        },
        {
            title: "2020 Gulfood Dubai",
            imgUrl: [
                gulfood20201,
                gulfood20202,
                gulfood20203,
                gulfood20204,
                gulfood20205,
            ],
        },
        {
            title: "2019 Gulfood Dubai",
            imgUrl: [gulfood20191, gulfood20192, gulfood20193],
        },
        {
            title: "2019 Anuga Colonia",
            imgUrl: [anuga20191, anuga20192],
        },
        {
            title: "2018 Sial Paris",
            imgUrl: [sial20181, sial20182],
        },
        {
            title: "2018 Gulfood Dubai",
            imgUrl: [gulfood20181, gulfood20182, gulfood20183, gulfood20184],
        },
        {
            title: "2017 Forum Brasileiro do Feijao",
            imgUrl: [forum20171, forum20172],
        },
        {
            title: "2017 Anuga Colonia",
            imgUrl: [anuga20171, anuga20172],
        },
        {
            title: "2016 Forum Brasileiro do Feijao",
            imgUrl: [forum20161, forum20162],
        },
        {
            title: "2016 Anuga",
            imgUrl: [anuga20161, anuga20162, anuga20163, anuga20164],
        },
        {
            title: "2016 Gulfood Dubai",
            imgUrl: [gulfood20161, gulfood20162, gulfood20163],
        },
        {
            title: "2015 APA San Pablo",
            imgUrl: [apa20151, apa20152, apa20153],
        },
    ]),
    (infoPlanta = [
        {
            title: "",
            imgUrl: [planta1],
        },
        {
            title: "",
            imgUrl: [planta2],
        },
        {
            title: "",
            imgUrl: [planta3],
        },
        {
            title: "",
            imgUrl: [planta4],
        },
        {
            title: "",
            imgUrl: [planta5],
        },
        {
            title: "",
            imgUrl: [planta6],
        },
        {
            title: "",
            imgUrl: [planta7],
        },
        {
            title: "",
            imgUrl: [planta8],
        },
        {
            title: "",
            imgUrl: [planta9],
        },
        {
            title: "",
            imgUrl: [planta10],
        },
        {
            title: "",
            imgUrl: [planta11],
        },
        {
            title: "",
            imgUrl: [planta12],
        },
        {
            title: "",
            imgUrl: [planta13],
        },
        {
            title: "",
            imgUrl: [planta14],
        },
        {
            title: "",
            imgUrl: [planta15],
        },
        {
            title: "",
            imgUrl: [planta16],
        },
        {
            title: "",
            imgUrl: [planta17],
        },
        {
            title: "",
            imgUrl: [planta18],
        },
    ]),
];

export default info;
