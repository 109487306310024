import '../../css/instituciones.css'
import Gpc from '../../assets/gpc.png'
import tucuman from '../../assets/tucuman.png'
import clera from '../../assets/clera.png'
import iso from '../../assets/iso.png'
import haccp from '../../assets/haccp.png'
import { useTranslation } from "react-i18next";

export const Instituciones = () => {
    const { t, i18n } = useTranslation(["translation"])
    return (
        <section className='ordenGeneralInstituciones'>
            <article>
                <h4 className='tituloInsituciones'> {t("homePage.institutions.associated")} </h4>
                <div className='ordenImagenesInstituciones'>
                    <img src={Gpc} alt="logo de gpc" className='imagenInstituciones' />
                    <img src={tucuman} alt="logo de tucuman" className='imagenTuc' />
                    <img src={clera} alt="logo de clera" className='imagenInstituciones' />
                </div>
            </article>
            <article>
                <h4 className='tituloInsituciones'>  {t("homePage.institutions.certificates")} </h4>
                <div className='ordenLogosInstituciones'>
                    <img src={iso} alt="insignia iso" className='imagenLogos' />
                    <img src={haccp} alt="insignia haccp" className='imagenLogos' />
                </div>
            </article>

        </section>
    )
}