import { useState } from "react";
import Carousel from "better-react-carousel";
import { useTranslation } from "react-i18next";
import "../../css/carrousel.css";
import "../../css/modal.css";

export const Carrousel = object => {
    const { t, i18n } = useTranslation(["translation"]);
    const [modalState, setModalState] = useState("");

    const [query, setQuery] = useState("");

    const CarrouselModal = sliderItem => {
        const modalItem = sliderItem.display;

        return (
            <section className={"modalContainer " + modalState}>
                <div className="modalCloseOverlay" onClick={handleModalState}>
                    <p className="modalCloseOverlayText">
                        {t("carrouselItem.closeBtn")}
                    </p>
                </div>
                <Carousel
                    cols={1}
                    rows={1}
                    gap={10}
                    loop
                    showDots
                    hideArrow={modalItem.imgUrl.length <= 1 ? true : false}
                >
                    {modalItem.imgUrl.map(item => {
                        return (
                            <Carousel.Item key={item} className="modalItem">
                                <div className="modalItem__container">
                                    <img
                                        className="modalItem__img"
                                        width="100%"
                                        src={item}
                                        alt={modalItem.title}
                                    />
                                    {modalItem.title != "" ? (
                                        <p className="modalItem__title">
                                            {modalItem.title}
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </Carousel.Item>
                        );
                    })}
                </Carousel>
            </section>
        );
    };

    const handleQuery = e => {
        setQuery(JSON.parse(e.target.value));
        handleModalState();
    };
    const handleModalState = () => {
        setModalState(modalState == "" ? "active" : "");
    };

    return (
        <section className="ordenGeneralFerias">
            <div className="carrouselBg"></div>
            <div className="carrouselBg"></div>
            <h2 className="tituloFerias">{object.display.title}</h2>
            <Carousel
                cols={5}
                rows={1}
                gap={10}
                loop
                showDots
                mobileBreakpoint={700}
            >
                {object.display.itemsArray.map(item => {
                    const itemJson = JSON.stringify(item);

                    return (
                        <Carousel.Item
                            key={item.title}
                            className="carrouselItem"
                        >
                            <div className="carrouselItem__container">
                                <img
                                    className={
                                        "carrouselItem__img" +
                                        " " +
                                        (object.shadow ? "shadow" : "")
                                    }
                                    /* El shadow hace que la imagen se vea mas oscura o no desde fuera del carrousel */
                                    width="100%"
                                    src={item.imgUrl[0]}
                                    alt={item.title}
                                />
                                <p className="carrouselItem__title">
                                    {item.title}
                                </p>
                                <input
                                    className="carrouselItem__button"
                                    type="button"
                                    value={itemJson}
                                    onClick={handleQuery}
                                />
                            </div>
                        </Carousel.Item>
                    );
                })}
            </Carousel>
            {query != "" ? <CarrouselModal display={query} /> : ""}
        </section>
    );
};
