import cas from '../../assets/cas-logo.png'
import '../../css/loadScreen.css'



const LoadScreen = () => {
    return (
        <div className='loadScreen'>
            <img src={cas} alt="Logotipo Cas" className='loadScreen__img' />
            <h1 className='loadScreen__title'>Loading translations...</h1>
        </div>
    )
}

export default LoadScreen;
